import { type ReactNode, useRef } from 'react';
import { useLocale, useNumberField } from 'react-aria';
import type {
  NumberFieldProps as RACNumberFieldProps,
  ValidationResult,
} from 'react-aria-components';
import {
  Button as RACButton,
  Group as RACGroup,
  Label as RACLabel,
} from 'react-aria-components';
import { useNumberFieldState } from 'react-stately';
import { isFunction } from 'remeda';

import { ErrorFilled } from '../../icons/error-filled.js';
import { InfoOutline } from '../../icons/info-outline.js';
import { lightDark } from '../../media.js';
import { vars } from '../../theme-contract.css.js';
import { Button } from '../button/button.js';
import { Input } from '../input/input.js';
import { Tooltip, TooltipTrigger } from '../tooltip/tooltip.js';
import {
  descriptionStyles,
  fieldErrorStyles,
  hiddenButtonStyles,
  hintStyles,
  inputContainerStyles,
  inputStyles,
  labelContainerStyles,
  labelStyles,
  numberFieldStyles,
} from './number-field.css.js';

export interface NumberFieldProps extends RACNumberFieldProps {
  label?: ReactNode;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  placeholder?: string;
  hint?: string;
}

export function NumberField(props: NumberFieldProps) {
  const {
    label,
    description,
    errorMessage,
    placeholder,
    isDisabled,
    isReadOnly,
    isRequired,
    hint,
    name,
  } = props;
  const ref = useRef(null);
  const { locale } = useLocale();

  const state = useNumberFieldState({ ...props, locale });

  const {
    labelProps,
    inputProps,
    errorMessageProps,
    descriptionProps,
    incrementButtonProps,
    decrementButtonProps,
    ...validation
  } = useNumberField({ validationBehavior: 'native', ...props }, state, ref);

  return (
    <div
      className={numberFieldStyles}
      data-disabled={isDisabled || undefined}
      data-invalid={validation.isInvalid || undefined}
      data-readonly={isReadOnly || undefined}
      data-required={isRequired || undefined}
      data-test="NumberField"
    >
      <div className={labelContainerStyles}>
        <RACLabel {...labelProps} className={labelStyles}>
          {label}
        </RACLabel>
        {hint ?
          <TooltipTrigger closeDelay={100} delay={200}>
            <Button
              aria-label="Tooltip Trigger"
              color="transparent"
              css={{
                color: lightDark(
                  `${vars.color.gray600}`,
                  vars.color.brandWhite,
                ),
              }}
              data-test="number-hint-button"
              kind="tertiary"
              size="icon"
            >
              <InfoOutline size={16} />
            </Button>
            <Tooltip className={hintStyles} data-hint="true" placement="top">
              {hint}
            </Tooltip>
          </TooltipTrigger>
        : null}
      </div>
      <RACGroup>
        <span aria-hidden="true" className={hiddenButtonStyles}>
          <RACButton {...incrementButtonProps} aria-hidden="true">
            +
          </RACButton>
        </span>
        <div className={inputContainerStyles}>
          <Input
            {...inputProps}
            className={inputStyles}
            name={name}
            placeholder={placeholder}
            ref={ref}
            required={isRequired}
            type="number"
          />
        </div>
        <span aria-hidden="true" className={hiddenButtonStyles}>
          <RACButton {...decrementButtonProps} aria-hidden="true">
            -
          </RACButton>
        </span>
      </RACGroup>
      {validation.isInvalid ?
        // Error message
        <div {...errorMessageProps} className={fieldErrorStyles}>
          <ErrorFilled size={{ mobile: 16, medium: 18 }} />
          {errorMessage ?
            isFunction(errorMessage) ?
              errorMessage(validation)
            : errorMessage
          : validation.validationErrors.join(' ')}
        </div>
      : description ?
        // Description
        <div
          {...descriptionProps}
          className={descriptionStyles}
          slot="description"
        >
          {description}
        </div>
      : null}
    </div>
  );
}
